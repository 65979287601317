import React from "react";
import { SubpageLayout } from "../components/SubpageLayout";

function ContactThankYouPage() {
  return (
    <SubpageLayout>
      <div className="flex-column items-center justify-center">
        <h2 className="text-2xl font-bold inline-block my-8 p-3 text-center">
          Twoja wiadomość do nas dotarła
        </h2>
        <p>
          <strong>Dziękujemy za wiadomość. Wkrótce skontaktujemy się z Tobą.
          </strong>
        </p>
        <div className="flex flex-col lg:flex-row lg:justify-around lg:w-full mt-10">
          <form
            action="/"
          >
            <button
              type="submit"
              className="flex mx-auto leading-loose justify-between py-3 pl-6 pr-4 bg-white hover:shadow-none try-item font-bold text-white uppercase rounded-full shadow-lg"
            >
              <p className="flex-row flex items-center">
                Wróć na stronę główną
                <i className="mx-2 fa fa-chevron-right"/>
              </p>
            </button>
          </form>
        </div>
      </div>
    </SubpageLayout>
  );
}

export default ContactThankYouPage;
